import '../components/Footer.css';
import { FaFacebook, FaGithub, FaHome, FaLinkedin, FaMailBulk, FaPhone } from 'react-icons/fa';

import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
<div className='footer-container'>
<div className='left'>
    <div className='location'>
<FaHome size={20} style={{color:"#fff", marginRight: "2rem"}} />
<div>
    <p>North Macedonia</p>
    <p>Skopje</p>
</div>
    </div>
    <div className='phone'>
    </div>
    <div className='email'>
    <h4><FaMailBulk size={20} style={{color:"#fff", marginRight: "2rem"}} />
    kpejkovska@gmail.com</h4>
    </div>
</div>

<div className='right'>
    <h4>About Me</h4>
    <p>I’m a junior frontend developer.Skilled in HTML5,CSS3,JavaScript,Bootstrap,Tailwind and React.js.Experienced in creating visually appealing and user-friendly interfaces,leveraging my creativity and attention to detail. I'm excited about the prospect of further refining my skills and making meaningful contributions to innovative and impactful web development initiatives. Let's build something great together!</p>
    <div className='social'>
    <FaLinkedin size={30} style={{color:"#fff", marginRight: "1rem"}} />
    <FaGithub size={30} style={{color:"#fff", marginRight: "1rem"}} />



    </div>
</div>
</div>
    </div>
  )
}

export default Footer