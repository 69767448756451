import React from 'react';
import './index.css';
import About from '../src/routes/About';
import Contact from '../src/routes/Contact';
import Home from '../src/routes/Home';
import Projects from '../src/routes/Projects';
import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';

function App() {
  return (
<>
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/projects' element={<Projects />} />
    <Route path='/about' element={<About />} />
    <Route path='/contact' element={<Contact />} />
  </Routes>
</>
  );
}

export default App;
