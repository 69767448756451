import '../components/HeroImg.css';
import IntroImg from '../assets/intro-bg.jpg';

import React from 'react'
import { Link } from 'react-router-dom';

const Heroimg = () => {
  return (
    <div className='hero'>
<div className='mask'>
<img className='intro-img' src={IntroImg} alt='IntroImg' />
</div>

<div className='content'>
<p>Hi,I'm a front-end developer.</p>
<h1>Web Development</h1>
<div>
    <Link to='/projects' className='btn btn-light'>Projects</Link>
    <Link to='/contact' className='btn btn-light'>Contact</Link>
    <Link to='/about' className='btn btn-light'>About</Link>

</div>  
</div>
    </div>
  )
}

export default Heroimg