import pro1 from '../assets/project1.png';
import pro2 from '../assets/project2.png';
import pro3 from '../assets/project3.png';

const ProjectCardData = [
    {
        imgsrc: pro1,
        title: "GPT-3 Website Design",
        text: "The Future is Now and You Just Need To Realize It. Step into Future Today & Make it Happen.",
        view: "https://www.kristinaportfolio1.site/"
    },

    {
        imgsrc: pro2,
        title: "Gericht Restaurant website design",
        text: "The Key To Fine Dining",
        view: "https://www.kristinaportfolio2.site/"
    },

    {
        imgsrc: pro3,
        title: "HooBank Website design",
        text: "The next generation of payment method.",
        view: "https://www.kristinaportfolio3.site/"
    }

];

export default ProjectCardData;