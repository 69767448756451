import React from 'react';
import '../components/Skills.css';
import { FaHtml5, FaCss3, FaJs, FaReact } from 'react-icons/fa';
import { IoLogoCss3 } from 'react-icons/io5'; 
import { BsFillBootstrapFill } from "react-icons/bs";
import { FiWind } from "react-icons/fi";

const SkillsSection = () => {
  const skills = [
    { name: 'HTML5', icon: <FaHtml5 /> },
    { name: 'CSS3', icon: <IoLogoCss3 /> }, 
    { name: 'JavaScript', icon: <FaJs /> },
    { name: 'React', icon: <FaReact /> },
    { name: 'Tailwind', icon: <FiWind /> }, 
    { name: 'Bootstrap', icon: <BsFillBootstrapFill /> }, 
  ];

  return (
    <section className="skills-section">
      <h1>Skills</h1>
      <ul className="skills-list">
        {skills.map((skill, index) => (
          <li key={index} className="skill-item">
            {skill.icon} {skill.name}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default SkillsSection;
