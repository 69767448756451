import { Link } from 'react-router-dom';
import '../components/AboutContent.css';
import aboutimg from '../assets/hero-removebg-preview.png';

import React from 'react'
import SkillsSection from './Skills';

const AboutContent = () => {
  return (
    <div className='about'>
<div className='left'>
<h1>Who Am I?</h1>
<p>As a junior frontend developer, I specialize in creating visually appealing and responsive web interfaces using HTML, CSS, and JavaScript. I collaborate with designers and contribute to the implementation of frontend frameworks like React. My role involves troubleshooting and debugging, ensuring the seamless functionality of websites. Committed to continuous learning, I stay updated on industry trends and best practices to optimize performance. I am a proactive team player, dedicated to producing clean, maintainable code and contributing to a positive and dynamic development environment.</p>
<Link to="/contact">
    <button className='btn'>Contact Me</button>
</Link>
</div>

 <div className='right'>
<div className='image-container'>
<div className='img-stack'>
<img src={aboutimg} alt='me' className='img' />
</div>
</div>
</div> 

<div>
  <SkillsSection />
</div>
    </div>
  )
}

export default AboutContent